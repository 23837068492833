import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { gql, request, type Variables } from 'graphql-request';
import { useCallback } from 'react';

import { getGraphQlEndpoint } from '../api/backendAPI';
import useCurrency from '../app/hooks/useCurrency';
import { getCartIdCookie } from '../utils/cartUtils';
import type {
  Products,
  Products_content_listProducts,
  Products_content_listProducts_variants,
  ProductsVariables,
} from './types/Products';

export type { Products, ProductsVariables } from './types/Products';

/*
 * withQueryKey mutates the response. :(
 * Below are types to match.
 */
export interface Variant
  extends Omit<
    Products_content_listProducts_variants,
    'id' | 'variantId' | 'productId'
  > {
  id: number;
  variantId: number;
  productId: number;
  price: number;
}

export interface ListProduct
  extends Omit<Products_content_listProducts, 'id' | 'variants'> {
  id: number;
  variants: Variant[];
}

//TODO: GROW-1607 Remove oldPrice after all gen3 rings sell out
const query = gql`
  query Products(
    $cartId: String
    $region: String
    $currency: String
    $flags: [String]
    $productType: String
  ) {
    content(region: $region, currency: $currency, enabledFeatureFlags: $flags) {
      listProducts(productType: $productType) {
        id
        productKey
        title
        handle
        product_type
        analytics_category
        checkoutDescription
        colorMsgKey
        styleMsgKey
        titleMsgKey
        price {
          amount
          currencyCode
        }
        oldPrice {
          amount
          currencyCode
        }
        comparePrice {
          amount
          currencyCode
        }
        discountedPrice(cartId: $cartId) {
          originalPrice {
            amount
            currencyCode
          }
          discountedPrice {
            amount
            currencyCode
          }
        }
        selectedTraits {
          name
          value
        }
        inStock
        isRing
        seoParams {
          title
          description
        }
        options {
          name
          value
        }
        images {
          originalSrc
          thumbnailSrc
          alt
          fullBleedThumbnail
        }
        lineItemImage {
          originalSrc
          alt
        }
        variants {
          sku
          skuCode
          id: variantId
          variantId
          productId
          bbySkuId
          selectedOptions {
            name
            value
          }
        }
        wizard {
          styleStep {
            carouselImages {
              altMsgKey
              contentTitle
              content {
                copy
                heading
                iconName
              }
              inverseText
              mobileSrc
              originalSrc
              thumbnailSrc
              originalSrcKey
            }
            image {
              altMsgKey
              inverseText
              originalSrc
            }
            selector {
              altMsgKey
              descMsgKey
              originalSrc
              swatch {
                altMsgKey
                originalSrc
                slug
              }
            }
          }
          finishStep {
            carouselImages {
              altMsgKey
              contentTitle
              content {
                copy
                heading
                iconName
              }
              inverseText
              mobileSrc
              originalSrc
              thumbnailSrc
              originalSrcKey
            }
            image {
              altMsgKey
              inverseText
              originalSrc
            }
            selector {
              altMsgKey
              originalSrc
            }
          }
          sizeStep {
            carouselImages {
              altMsgKey
              contentTitle
              content {
                copy
                heading
                iconName
              }
              inverseText
              mobileSrc
              originalSrc
              thumbnailSrc
              originalSrcKey
            }
            image {
              altMsgKey
              inverseText
              originalSrc
            }
            selector {
              altMsgKey
              originalSrc
            }
          }
          warrantyStep {
            image {
              altMsgKey
              inverseText
              originalSrc
            }
            selector {
              altMsgKey
              originalSrc
            }
          }
        }
      }
    }
  }
`;

export const DEFAULT_VARS: ProductsVariables = {
  cartId: null,
  region: null,
  currency: null,
  flags: [],
  productType: null,
};

export const PRODUCTS_ALL_KEY = 'All';

export function mapProductsData(data: Products) {
  // Reshape results to match the legacy data structure.
  const products = data.content?.listProducts ?? [];
  return products
    .filter(
      (product): product is Products_content_listProducts => product !== null,
    )
    .map((product) => {
      const variants: Variant[] = (product.variants ?? [])
        .filter(
          (variant): variant is Products_content_listProducts_variants =>
            variant !== null,
        )
        .map((variant) => ({
          ...variant,
          id: Number(variant.id),
          variantId: Number(variant.variantId),
          productId: Number(variant.productId),
          // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
          price: product.price?.amount as number,
        }));
      return {
        ...product,
        id: Number(product.id),
        variants,
      };
    });
}

export function withQueryKey(vars: ProductsVariables) {
  const cartId = getCartIdCookie();

  // Enforce that we always have consitent cache keys that match the graphql query
  const queryVars: ProductsVariables = {
    ...DEFAULT_VARS,
    ...vars,
    cartId,
  };

  return {
    queryKey: [
      'useProductsData',
      queryVars.productType ?? PRODUCTS_ALL_KEY, // Use "All" key when no productType
      { vars: queryVars },
    ],
    queryFn: async (): Promise<ListProduct[]> => {
      const url = getGraphQlEndpoint()!;
      const data: Products = await request(url, query, queryVars as Variables);
      return mapProductsData(data);
    },
  };
}

export function useMembershipProduct() {
  const currency = useCurrency();
  return useQuery({
    ...withQueryKey({
      currency: currency.currencyCode,
      productType: 'Accessory',
    }),
    select: useCallback((data: ListProduct[]) => {
      return {
        annual: data.find(
          (product) => product.handle === 'one-year-membership',
        ),
        monthly: data.find((product) => product.handle === 'subscription'),
      };
    }, []),
  });
}

export function useProductsData({
  enabled,
}: {
  enabled: boolean;
}): UseQueryResult<ListProduct[], Error> {
  const currency = useCurrency();

  return useQuery({
    ...withQueryKey({
      currency: currency.currencyCode,
    }),
    enabled,
  });
}
