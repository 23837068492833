import { useEffect, useRef } from 'react';

import isBrowser from '@/utils/isBrowser';

interface UseTimeoutState {
  readonly cancelTimeout: () => void;
}

const useTimeout = (callback: () => void, delay: number): UseTimeoutState => {
  const _callback = useRef(callback);
  const timeoutRef = useRef<number | undefined>(undefined);

  const cancelTimeout = () => {
    if (isBrowser()) {
      window.clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      _callback.current();
    }, delay);

    return () => {
      cancelTimeout();
    };
  }, [delay]);

  return {
    cancelTimeout,
  };
};

export default useTimeout;
