import React from 'react';
import { useConfig, useExperiment } from 'statsig-react';

import Gen3Holiday2025Hero from './_heros/Gen3Holiday2025Hero';
import Holiday2024Hero from './_heros/Holiday2024Hero';
import OR4Hero from './_heros/OR4Hero';

const heroLookup: Record<string, () => React.JSX.Element> = {
  OR4Hero: () => <OR4Hero />,
  Holiday2024Hero: () => <Holiday2024Hero />,
  Gen3Holiday2025Hero: () => <Gen3Holiday2025Hero />,
};

type HeroType =
  | 'OR4Hero'
  | 'Holiday2024Hero'
  | 'BoxingDay2024Hero'
  | 'BlackFriday2024Hero'
  | 'Gen3Holiday2025Hero';

// If statsig misbehaves we will fallback to this
const FallbackHero = OR4Hero;

const HomeHeroGate = () => {
  const configHero = useConfig('home_hero').config.getValue(
    'hero',
    'OR4Hero',
  ) as HeroType;

  // Temporary experiment to test different hero components.  The winner will be promoted to Hero config when experiment complete
  const experimentHero = useExperiment('or4_vs_gen3_hero').config.getValue(
    'hero',
    'Holiday2024Hero',
  ) as HeroType;

  const Hero = heroLookup[experimentHero] ?? heroLookup[configHero];

  return Hero ? <Hero /> : <FallbackHero />;
};

export default HomeHeroGate;
