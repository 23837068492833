import { RightArrowIcon, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { useButton, useFocusRing, useOverlayTrigger } from 'react-aria';
import type { OverlayTriggerProps, OverlayTriggerState } from 'react-stately';
import { useOverlayTriggerState } from 'react-stately';

import useUserQuiz from '@/app/experiments/D2CUserQuiz/useUserQuiz';
import useTimeout from '@/app/hooks/useTimeout';
import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@/utils/sessionStorage';

import RingAssessmentModalOverlay from './RingAssessmentModalOverlay';

export interface RingAssessmentModalTriggerProps extends OverlayTriggerProps {
  readonly autoShowDelay: number;
  readonly children: (state: OverlayTriggerState) => JSX.Element;
}

export const STORAGE_KEY = 'quizModalShown';
export const MODAL_OPENED_FROM = 'quizModalOpenedFrom';

const RingAssessmentModalTrigger = ({
  autoShowDelay,
  children,
  ...props
}: RingAssessmentModalTriggerProps): JSX.Element => {
  const userQuizActive = useUserQuiz();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const t = useTranslations();
  const state = useOverlayTriggerState(props);
  const { overlayProps, triggerProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  const { buttonProps } = useButton(triggerProps, buttonRef);
  const handleTriggerClick = () => {
    state.setOpen(true);
    writeToSessionStorage(MODAL_OPENED_FROM, 'tab_button');
  };
  const [modalHasShown, setModalHasShown] = useState(false);

  const { cancelTimeout } = useTimeout(() => {
    state.setOpen(true);
  }, autoShowDelay);

  // Initially set shown state from value in sessionStorage
  useEffect(() => {
    const _modalHasShown = readFromSessionStorage(STORAGE_KEY) === 'true';
    setModalHasShown(_modalHasShown);
  }, []);

  // Prevent auto-opening of modal after it's been opened once
  useEffect(() => {
    if (state.isOpen) {
      if (!modalHasShown) {
        setModalHasShown(true);
        writeToSessionStorage(STORAGE_KEY, 'true');
      }
    } else if (modalHasShown) {
      cancelTimeout();
    }
  }, [cancelTimeout, state.isOpen, modalHasShown]);

  if (userQuizActive) {
    return (
      <>
        <button
          {...buttonProps}
          {...focusProps}
          className={cx(
            'fixed bottom-32 right-0 z-50 flex origin-bottom-right items-center gap-x-2 text-nowrap rounded-t-lg border border-b-0 border-solid border-transparent bg-slate-100 px-6 py-3 text-slate-800 transition-colors duration-300 hover:border-sandstone-400 hover:bg-gray-200 hover:shadow-md',
            {
              'focus:outline-none': !isFocusVisible,
            },
          )}
          data-cy="open-assessment-modal-button"
          onClick={handleTriggerClick}
          style={{
            transform: 'rotate(-90deg) translateX(100%)',
          }}
          type="button"
        >
          <TypographyV3 Element="span" weight="bold">
            {t('d2c_quiz_take_quiz')}
          </TypographyV3>
          <RightArrowIcon height="15" width="15" />
        </button>
        <AnimatePresence>
          {state.isOpen && (
            <RingAssessmentModalOverlay {...props} state={state}>
              {cloneElement(children(state), overlayProps)}
            </RingAssessmentModalOverlay>
          )}
        </AnimatePresence>
      </>
    );
  } else {
    return <></>;
  }
};

export default RingAssessmentModalTrigger;
