import type {
  EcomFooterProps,
  FootnotesProps,
  HeaderV3Props,
  LayoutV3Props,
} from '@jouzen/ecom-components';
import { useHeaderContext } from '@jouzen/ecom-components';
import { noop } from 'lodash';
import { usePathname, useRouter } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';
import { useCallback, useState } from 'react';

import useEyebrowProps from '@/app/components/Eyebrow/hooks/useEyebrowProps';
import type { EyebrowProps } from '@/app/components/Eyebrow/types';
import { useCartContext } from '@/app/contexts/CartProvider';
import useFootnotesProps from '@/app/hooks/useFootnotesProps';
import usePathnameWithoutLocale from '@/app/hooks/usePathnameWithoutLocale';

import { FooterImageComponent } from '../FooterImageComponent';
import { MenuImageComponent } from '../MenuImageComponent';
import useFooterConfig from './useFooterConfig';
import useHeaderConfig from './useHeaderConfig';
import useMenuConfig from './useMenuConfig';
import usePageAnalytics from './usePageAnalytics';
import useShopButton from './useShopButton';

interface PageLayoutProps {
  parentContainerProps: LayoutV3Props['containerProps'];
  parentFooterProps?: Partial<EcomFooterProps> | undefined;
  parentHeaderProps?: Partial<HeaderV3Props> | undefined;
}

interface PageLayoutHook
  extends Omit<LayoutV3Props, 'mainLabel' | 'children' | 'footerProps'> {
  footerProps: EcomFooterProps;
  observeNode: (node: HTMLDivElement | null) => void;
}

export const usePageLayout = ({
  parentContainerProps,
  parentFooterProps,
  parentHeaderProps,
}: PageLayoutProps): PageLayoutHook => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const { inverse } = useHeaderContext()!;
  const t = useTranslations();
  const pathname = usePathname();
  const locale = useLocale();
  const router = useRouter();

  const eyebrowProps: EyebrowProps = useEyebrowProps();
  const headerLinks = useHeaderConfig();
  const { observeNode, shopButton } = useShopButton(pathname);
  const menuLinks = useMenuConfig(pathname);
  const footnotePathname = usePathnameWithoutLocale();
  const footnotesProps: FootnotesProps = useFootnotesProps(footnotePathname);
  const footerLinks = useFooterConfig();

  const cartContext = useCartContext();
  const refreshCart = cartContext?.refreshCart;
  const cart = refreshCart?.data?.cart;

  const {
    onFooterLinkClick,
    onFooterSocialLinkClick,
    onHamburgerClick,
    onHeaderLinkClick,
    onMenuLinkClick,
    onMenuShopButtonClick,
    onShopButtonClick,
    sendEmailSubmitAnalytics,
  } = usePageAnalytics();

  const handleNewsletterFormSubmit = async (email: string): Promise<void> => {
    await sendEmailSubmitAnalytics(email).catch(noop);
    if (email) setEmailSubmitted(true);
  };

  const handleHeaderLinkClick = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
      // handle analytics
      await onHeaderLinkClick(event);
      // handle custom link onClick logic
      if (parentHeaderProps?.onClick) parentHeaderProps.onClick(event);
    },
    [onHeaderLinkClick, parentHeaderProps],
  );

  const handleShoppingCartClick = useCallback((): void => {
    router.push(`/${locale}/cart`);
  }, [locale, router]);

  const { h1: heading, ...rest } = parentContainerProps;
  const h1 = heading ? t(heading) : '';

  const containerProps = {
    ...rest,
    h1,
  } as LayoutV3Props['containerProps'];

  const headerProps = {
    // mobile menu "Shop" category should be open by default
    accordionOpenAtIndex: 1,
    currentPath: pathname,
    inverse,
    count: cart?.itemCountForIcon ?? 0,
    menuImageComponent: <MenuImageComponent />,
    shopButton,
    ...parentHeaderProps,
    headerLinks,
    menuLinks,
    onClick: handleHeaderLinkClick,
    onMenuLinkClick,
    onMenuShopButtonClick,
    onHamburgerClick,
    onShopButtonClick,
    onShoppingCartClick: handleShoppingCartClick,
  };

  const footerProps = {
    ...parentFooterProps,
    emailSubmitted,
    footerLinks: footerLinks?.footerLinks,
    legalLinks: footerLinks?.legalLinks,
    footerImageComponent: <FooterImageComponent />,
    onFooterLinkClick,
    onSocialLinkClick: onFooterSocialLinkClick,
    onNewsletterFormSubmit: handleNewsletterFormSubmit,
  };

  return {
    containerProps,
    eyebrowProps,
    footnotesProps,
    headerProps,
    footerProps,
    observeNode,
  };
};
