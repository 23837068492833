import { ButtonV3, CloseXIcon, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useRef } from 'react';
import { useDialog, useFocusRing } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import { EventType } from '@/analytics/types';
import Image from '@/app/components/Image';
import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@/utils/sessionStorage';

import { MODAL_OPENED_FROM } from './RingAssessmentModalTrigger';

const QUIZ_PATH = '/ring-assessment';
export const PAGE_OPENED_FROM = 'quizModalOpenedFrom';

const RingAssessmentModalContent = ({
  close, // eslint-disable-line @typescript-eslint/unbound-method
}: OverlayTriggerState): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const t = useTranslations();
  const { dialogProps, titleProps } = useDialog({}, wrapperRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const pathname = usePathname();
  const handleStartQuizClicked = () => {
    void window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'Start User Quiz',
      location: readFromSessionStorage(MODAL_OPENED_FROM) ?? 'modal_timer',
      action: 'start quiz',
      path: QUIZ_PATH,
    });
    writeToSessionStorage(PAGE_OPENED_FROM, pathname);
  };

  return (
    <div
      {...dialogProps}
      {...focusProps}
      className={cx(
        'relative w-full shrink-0 overflow-hidden rounded-[10px] bg-sandstone-100 lg:flex lg:w-[776px] maxlg:max-w-[600px]',
        {
          'focus:outline-none': !isFocusVisible,
        },
      )}
      data-cy="ring-assessment-modal-dialog"
      ref={wrapperRef}
    >
      <button
        className="absolute right-2 top-5 flex size-11 items-center justify-center text-sandstone-500 lg:top-3"
        data-cy="ring-assessment-modal-close"
        onClick={close}
        type="button"
      >
        <CloseXIcon className="size-8" />
        <span className="sr-only">{t('close')}</span>
      </button>
      <div className="lg:w-1/2 lg:shrink-0 maxlg:hidden">
        <Image
          alt=""
          height={483}
          src="blue-sky/d2c-quiz/d2c-quiz-modal-feature.jpg?ar=4:5&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.46&fp-z=1"
          width={388}
        />
      </div>
      <div className="px-10 pb-12 pt-16 lg:w-1/2 lg:shrink-0 lg:py-14">
        <h2 className="mb-12 maxlg:text-center" {...titleProps}>
          <TypographyV3 className="text-4xl text-sandstone-500" Element="span">
            {t.rich('d2c_cta_modal_copy')}
          </TypographyV3>
        </h2>
        <ButtonV3
          className="w-full"
          href={QUIZ_PATH}
          onClick={handleStartQuizClicked}
          size="large"
          variant="outlined-dark"
        >
          {t('d2c_start')}
        </ButtonV3>
      </div>
    </div>
  );
};

export default RingAssessmentModalContent;
