'use client';

import type { UseQueryResult } from '@tanstack/react-query';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useConfig } from 'statsig-react';

import {
  type CartSettingsJson,
  useCartSettings,
} from '@/queries/GetCartSettings';
import { useRefreshCart } from '@/queries/RefreshCart';
import { useSavePromotion } from '@/queries/SavePromotion';
import type { RefreshCart_cart_refreshCart } from '@/queries/types/RefreshCart';
import type { SavePromotion_cart_savePromotion } from '@/queries/types/SavePromotion';

export interface CartContextValues {
  cartSettings: UseQueryResult<CartSettingsJson>;
  refreshCart: UseQueryResult<RefreshCart_cart_refreshCart | null | undefined>;
  setUrlDiscountCode: Dispatch<SetStateAction<string>>;
  sitewideDiscount: UseQueryResult<
    SavePromotion_cart_savePromotion | null | undefined
  >;
  urlDiscount: UseQueryResult<
    SavePromotion_cart_savePromotion | null | undefined
  >;
}

export const CartContext = createContext<CartContextValues | null>(null);

const CartProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const cartSettings = useCartSettings();

  const {
    config: { value: sitewideDiscountConfig },
  } = useConfig('sitewide_discount');
  const sitewideDiscountCode = sitewideDiscountConfig['sitewideDiscount'] as
    | string
    | null;
  const sitewideDiscount = useSavePromotion(sitewideDiscountCode ?? '');

  const [urlDiscountCode, setUrlDiscountCode] = useState<string>('');
  const urlDiscount = useSavePromotion(urlDiscountCode);
  const fetchingDiscounts = sitewideDiscount.isLoading || urlDiscount.isLoading;

  const refreshCart = useRefreshCart(fetchingDiscounts);

  const value: CartContextValues = useMemo(
    () => ({
      cartSettings,
      refreshCart,
      setUrlDiscountCode,
      sitewideDiscount,
      urlDiscount,
    }),
    [cartSettings, refreshCart, sitewideDiscount, urlDiscount],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => useContext(CartContext);

export default CartProvider;
